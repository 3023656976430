<template>
  <div id="page-swish-merchant">

    <SwishMerchantEditorModal 
      ref="swish_merchant_editor_modal"
      :item="selected_merchant"
      @save="save_swish_merchant"
      @created="created"
      @updated="updated"
    />

    <h5 class="mb-6 ml-2">{{ $t('SWISH_MERCHANT.HEADER') }}</h5>

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('SWISH_MERCHANT.TAB_MERCHANTS')}}</v-tab>
        <v-tab>{{$t('SWISH_MERCHANT.TAB_SETTINGS')}}</v-tab>
        
        <v-tab-item>
          
          <SwishMerchantTable
            class="mt-4"
            ref="swish_merchants-table"
            :items="swish_merchants"
            @select="select_merchant_clicked"
            @delete="delete_merchant_clicked"
            @create="create_merchant_clicked"
          />
        </v-tab-item>
        <v-tab-item>

        </v-tab-item>
      </v-tabs>
    </b-card>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import SwishMerchantTable from '@/view/pages/ml/swish_merchant/SwishMerchantTable.vue';
import SwishMerchantEditorModal from '@/view/pages/ml/swish_merchant/SwishMerchantEditorModal.vue';

import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {
  name: 'SwishMerchant',
  mixins: [ toasts ],
  components: {
    SwishMerchantTable,
    SwishMerchantEditorModal
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    
  },
  data() {
    return {
      swish_merchants: [],

      selected_merchant: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    }
  },
  methods: {


    created(item) {
      this.$refs['swish_merchants-table'].refresh();
    },

    updated(item) {
      this.$refs['swish_merchants-table'].refresh();
    },

    select_merchant_clicked(item) {
      this.selected_merchant = item;

      this.$nextTick(()=>{
        this.$refs.swish_merchant_editor_modal.show();
      });
      
    },

    async delete_merchant_clicked(item) {
      try {
        const res = await axios.delete(`/swish/merchant/${item.id}`);

        if (res.status === 204) {
          this.$refs['swish_merchants-table'].refresh();

          this.toastr('success', this.$t('COMMON.OK'), this.$t('SWISH_MERCHANT.DELETED'));
        }
      }
      catch (err) {
        console.error('delete_merchant_clicked error', err);
      }
    },

    create_merchant_clicked() {
      this.selected_merchant = {};

      this.$nextTick(()=>{
        this.$refs.swish_merchant_editor_modal.show();
      });
      
    },

    
    async load_swish_merchants() {
      try {
        this.files = [];
        const res = await axios.get(`/swish/merchant/company/${this.currentCompanyId}`)

        if (res.status === 200) {
          this.swish_merchants = res.data;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SWISH_MERCHANT.UNABLE_LOAD_FILES'));
      }
    },
    
    async load_files() {
      try {
        this.files = [];
        const res = await axios.get(`/file?company_id=${this.currentCompanyId}&period_id=${this.currentPeriodId}&file_type=SWISH_CERT`)

        if (res.status === 200) {
          this.files = res.data;

          if (show_file_id !== undefined) {
            const f = this.files.find(item => item.file_id === show_file_id);

            if (f !== undefined) {
              this.form = f;
            }
          }
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SWISH_MERCHANT.UNABLE_LOAD_FILES'));
      }
    },

    async download_file(file_id) {
      try {
        const res = await axios.get(`/file/${file_id}`);

        if (res.status === 200) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          return;
        }
        
      }
      catch (err) {
        console.error('download_file', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
    },
    
  }
};
</script>
<style>
.v-window {
  overflow: visible !important;
}
</style>
